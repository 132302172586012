$purple: #3e3135;
$green: #594f23;
$yellow-green: #7c8243;
$warn: red;
$orange: #c97e5d;
$brown: #725846;
$blue: #c9f0f2;
$darker-blue: #72cdd7;
$white: #f3f0e6;
$gray: #a5a4a0;

$box-shadow: -1px 2px 10px -4px rgba(62, 49, 53, 0.75);

@mixin blobby-button-mixin {
  background-color: mix($yellow-green, black, 75%);
  border: none;
  padding: 15px;
  color: $white;
  font-family: mr-eaves-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  border-radius: 60px 45px 45px 25px;
  transition: 0.2s;
  margin: 10px;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      animation: blobby 2s infinite;
      animation-timing-function: ease-in-out;
    }
  }
  @keyframes blobby {
    0% {
      border-radius: 60px 45px 45px 35px;
    }
    25% {
      border-radius: 35px 60px 45px 45px;
    }
    50% {
      border-radius: 45px 35px 60px 45px;
    }

    75% {
      border-radius: 45px 45px 35px 60px;
    }

    100% {
      border-radius: 60px 45px 45px 35px;
    }
  }
}

@mixin swinging-animation-mixin($name, $rotate1, $rotate2) {
  @keyframes #{$name} {
    0% {
      transform: rotate($rotate1);
    }
    50% {
      transform: rotate($rotate2);
    }
    100% {
      transform: rotate($rotate1);
    }
  }
}

@mixin fade-in-mixin($name, $start, $end) {
  @keyframes #{$name} {
    0% {
      opacity: $start;
    }
    100% {
      opacity: $end;
    }
  }
}

@mixin slide-mixin($name, $X1, $X2, $Y1) {
  @keyframes #{$name} {
    0% {
      background-position: $X1 $Y1;
    }
    100% {
      background-position: $X2;
    }
  }
}
