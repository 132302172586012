@import "../../core/css/variables.scss";
.Navigation {
  .siteNav {
    // z-index: 600;
    width: 100vw;
    padding: 0px;
    margin: 0px;
    &.mobileOnly {
      display: none;
    }
    &.desktopOnly {
      position: absolute;
      display: block;
      height: 75px;

      .navList {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        li {
          margin: 10px;
          z-index: 1000;
          &.active {
            button,
            a {
              text-decoration: underline;
              font-weight: bold;
            }
          }
          button,
          a {
            text-decoration: none;
            @include blobby-button-mixin;
          }
        }
      }
    }
    @media only screen and (max-width: 1050px) {
      &.desktopOnly {
        display: none;
      }
      &.mobileOnly {
        display: flex;
        flex-flow: row nowrap;
        width: 100vw;
        background-color: $purple;
        color: $white;
        margin: 0px;
        justify-content: flex-end;
        align-items: center;
        h2 {
          font-size: 1.5em;
          margin: 0px;
          span {
            font-family: mrs-eaves, serif;
          }
        }
        &.active {
          a {
            text-decoration: underline;
          }
        }
        a,
        button {
          background-color: $purple;
          color: $white;
          border: none;
          cursor: pointer;
          text-decoration: none;
          border-radius: 4px;
          font-size: 1.5em;
          display: flex;
          align-items: center;
          margin: 5px;
          &.active {
            text-decoration: underline;
          }
          svg {
            fill: $white;
          }
        }
        // .fadeMenu {
        //   .navList {
        //     li {
        //       z-index: 1000;
        //       // width: 100vw;
        //       text-align: center;
        //       align-self: stretch;
        //       &.active {
        //         button,
        //         a {
        //           text-decoration: underline;
        //         }
        //       }
        //       button,
        //       a {
        //         text-decoration: none;
        //         color: $white;
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}
