@import "core/css/variables.scss";

.App {
  text-align: center;
  color: $purple;
  background-color: $white;

  h1 {
    font-family: mrs-eaves-roman-small-caps, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 5rem;
    @media only screen and (max-width: 600px) {
      font-size: 3rem;
    }
  }
  h1 > span,
  h2,
  h3 {
    font-family: mrs-eaves, serif;
    font-weight: 400;
    font-style: normal;
  }

  h4 {
    font-family: mr-eaves-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  ul {
    list-style-type: none;
    text-align: left;
    padding-left: 0px;
    margin-left: 0px;
  }

  h1 > span,
  h2 {
    font-size: 2.5rem;
    margin-bottom: 0px;
    @media only screen and (max-width: 600px) {
      font-size: 1.8rem;
    }
  }
  h3 {
    font-size: 3rem;
    margin-bottom: 0px;
    @media only screen and (max-width: 600px) {
      font-size: 1.7rem;
    }
  }

  h4 {
    font-size: 2.5rem;
    margin-bottom: 0px;
    @media only screen and (max-width: 600px) {
      font-size: 1.6rem;
    }
  }
  p,
  li,
  ul,
  a {
    font-size: 1.6rem;
    line-height: 1.5em;
  }
  a {
    color: $purple;
  }
}

.nav-item > a {
  color: $purple;
}

.scrollUp {
  background-color: $orange;
  color: $white;
  height: 3em;
  width: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

#flyPath {
  stroke: mix($purple, black, 50%);
}

.is_inverted {
  background-color: $purple !important;
  color: $white !important;

  a:not(.nav-item > a) {
    color: $white;
  }

  #IntroSection,
  #PortfolioGridSection,
  #YourIdeas,
  #SkillsSection,
  #ContactSection,
  #PortfolioItem,
  #projectPage,
  #GalleryGridSection {
    background-color: $purple !important;
    color: $white !important;
  }

  #flyPath {
    stroke: $white;
  }

  #PortfolioItem {
    background-color: $purple !important;
    color: $white !important;
    figure {
      background-color: mix($purple, black, 75%) !important;
      color: $white !important;
      figcaption {
        p {
          color: $white !important;
        }
      }
    }
  }
  #Footer {
    background-color: mix($purple, black, 75%) !important;
    color: $white !important;
  }
  #skillsIntroTextBox,
  #skillsTextBox {
    background-color: mix($purple, black, 75%) !important;
    color: $white;
    border-color: mix($purple, black, 75%) !important;
  }
}

@media (prefers-color-scheme: dark) {
  .is_inverted {
    background-color: $purple !important;
    color: $white !important;

    #IntroSection,
    #PortfolioGridSection,
    #YourIdeas,
    #SkillsSection,
    #ContactSection,
    #PortfolioItem,
    #projectPage,
    #GalleryGridSection {
      background-color: $purple !important;
      color: $white !important;
    }

    #flyPath {
      stroke: $white;
    }

    #PortfolioItem {
      figure {
        background-color: mix($purple, black, 75%) !important;
        figcaption {
          p {
            color: $white !important;
          }
        }
      }
    }

    #DesignForGood {
      background-color: mix($purple, black, 75%) !important;
    }
    #designForGoodSvgBorder,
    #skillsSvgBorderBottom {
      fill: mix($purple, black, 75%) !important;
    }
    #Footer {
      background-color: mix($purple, black, 75%) !important;
      color: $white !important;
    }
    #skillsIntroTextBox,
    #skillsTextBox {
      background-color: mix($purple, black, 75%) !important;
      color: $white;
      border-color: mix($purple, black, 25%) !important;
    }
    #contactForm {
      .makeStyles-textfield-1 {
        background-color: mix($purple, black, 75%) !important;
        .MuiFormLabel-root {
          color: $white;
        }
        .MuiInputBase-input {
          color: $white;
        }
      }
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
